import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import ptOrange from '../images/pt_orange.gif'
import ptBrown from '../images/pt_brown.gif'

const ProjectsPage = () =>
  <Layout>
    <Helmet title="Hexy Music &gt; Projects" />

    <main id="content">
      <h2>&raquo; Projects</h2>

      <h3><img src={ ptOrange } alt="" className="" /> Auckland, Jan 2007</h3>
      <p>Well now, those projects just never seem to get going do they. The project page is still here,
      I've been living in Auckland for 3 years and have all my lovely equipment bursting to go,
      but nothing to write abou...  erm, oh, to late... </p>

      <h3><img src={ ptBrown } alt="" className="" /> London, some far off time in 2003</h3>
      <p>There are several projects being worked on at present. However, most of the
      equipment used to record the demos found on this site is based in New Zealand
      and I am based in London for a few years.</p>
      <p>There will be new material very soon though.</p>
    </main>

    <aside id="ear">
      &raquo;
    </aside>
  </Layout>

export default ProjectsPage
