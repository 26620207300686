import React from 'react'
import { Link } from 'gatsby'

const copyrightYear = new Date().getFullYear()

const Footer = () =>
  <footer id="foot">
    <Link to="/copyright/">Copyright &copy; 1997 - {copyrightYear}. All rights reserved.</Link>
    <ul>
      <li><a href="#top">^ top</a></li>
    </ul>
  </footer>

export default Footer
