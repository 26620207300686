import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from './header'
import Footer from './footer'

import './layout.scss'

const Layout = ({
  children,
}) =>
  <Fragment>
    <Helmet title="Hexy Music">
      <html lang="en" />
      <meta name="Author" content="Dave Transom, hexymusic.com" />
      <meta name="Copyright" content="(c) 1997 - 2007 Dave Transom " />
    </Helmet>

    <div id="granddad">
      <Header />
      {children}
      <Footer />
    </div>
  </Fragment>

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
