import React from 'react'
import { Link } from 'gatsby'

const Header = () =>
  <header id="head">
    <h1>HexyMusic</h1>
    <ul>
      <li className="skip"><a href="#content">Skip navigation</a></li>
      <li><Link to="/">Main</Link></li>
      <li><Link to="/download/">Download</Link></li>
      <li><Link to="/projects/">Projects</Link></li>
      <li><Link to="/about/">About</Link></li>
      <li><Link to="/contact/">Contact</Link></li>
    </ul>
  </header>


export default Header
